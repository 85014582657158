import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import PropTypes from 'prop-types';
import React from 'react';

import { msalConfig } from './auth-config';
import GatsbyNavigationClient from './navigation-client';

const msalInstance = new PublicClientApplication(msalConfig);

const ProviderWrap = ({ element }) => {
  // The next 2 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
  const navigationClient = new GatsbyNavigationClient();
  msalInstance.setNavigationClient(navigationClient);
  return <MsalProvider instance={msalInstance}>{element}</MsalProvider>;
};

ProviderWrap.propTypes = {
  element: PropTypes.elementType,
};

ProviderWrap.defaultProps = {
  element: null,
};

export default ProviderWrap;
