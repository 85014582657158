/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import './src/styles/main.css';

import ProviderWrap from 'utils/msal/wrap-with-provider';

// Fire a pageload event whenever the route changes, initial
// load should have already produced an event
// eslint-disable-next-line import/prefer-default-export
export const onRouteUpdate = () => {
  if (window.yopix) {
    window.yopix('event', 'pageload');
  }
};

export const wrapRootElement = ProviderWrap;
