// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-behavior-genome-jsx": () => import("./../../../src/pages/behavior-genome.jsx" /* webpackChunkName: "component---src-pages-behavior-genome-jsx" */),
  "component---src-pages-data-monetization-jsx": () => import("./../../../src/pages/data-monetization.jsx" /* webpackChunkName: "component---src-pages-data-monetization-jsx" */),
  "component---src-pages-data-science-jsx": () => import("./../../../src/pages/data-science.jsx" /* webpackChunkName: "component---src-pages-data-science-jsx" */),
  "component---src-pages-finance-jsx": () => import("./../../../src/pages/finance.jsx" /* webpackChunkName: "component---src-pages-finance-jsx" */),
  "component---src-pages-growth-jsx": () => import("./../../../src/pages/growth.jsx" /* webpackChunkName: "component---src-pages-growth-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-intelligence-jsx": () => import("./../../../src/pages/intelligence.jsx" /* webpackChunkName: "component---src-pages-intelligence-jsx" */),
  "component---src-pages-marketing-advertising-jsx": () => import("./../../../src/pages/marketing-advertising.jsx" /* webpackChunkName: "component---src-pages-marketing-advertising-jsx" */),
  "component---src-pages-personalization-jsx": () => import("./../../../src/pages/personalization.jsx" /* webpackChunkName: "component---src-pages-personalization-jsx" */),
  "component---src-pages-platform-jsx": () => import("./../../../src/pages/platform.jsx" /* webpackChunkName: "component---src-pages-platform-jsx" */),
  "component---src-pages-saas-activate-index-jsx": () => import("./../../../src/pages/saas/activate/index.jsx" /* webpackChunkName: "component---src-pages-saas-activate-index-jsx" */),
  "component---src-pages-saas-activate-success-jsx": () => import("./../../../src/pages/saas/activate/success.jsx" /* webpackChunkName: "component---src-pages-saas-activate-success-jsx" */),
  "component---src-pages-solutions-jsx": () => import("./../../../src/pages/solutions.jsx" /* webpackChunkName: "component---src-pages-solutions-jsx" */)
}

