// Config object to be passed to MSAL on creation
export const msalConfig = {
  auth: {
    clientId: 'e3e34117-f3ba-4546-8eb0-aeda6d923825',
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: 'https://saas.yobi.ai/saas/activate',
    postLogoutRedirectUri: '/',
  },
  system: {
    allowNativeBroker: false, // Disables WAM Broker
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ['User.Read'],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};
